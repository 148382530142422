import React, { useState } from 'react';
import { showModal } from 'modules/common/components/Modal';
import { useApiLoading } from 'react-reqq';
import * as actions from '../actions';
import * as c from '../constants';
import InputMask from 'react-input-mask';

function Content({ onClose }) {
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    mobile: '',
    email: '',
    recovery_type: '',
  });

  const isLoading = useApiLoading(c.RECOVER, 'post');

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (onClose) => (e) => {
    e.preventDefault();
    actions.recoverId(form, () => {
      onClose();
    });
  };

  return (
    <form onSubmit={handleSubmit(onClose)} id="card-form">
      <div className="p-4 text-white">
        <div
          className="relative overflow-auto"
          style={{ paddingBottom: '56.7%' }}
        >
          <div className="absolute top-0 h-full w-full text-justify px-2 text-gray-400">
            <div className="w-full">
              <div className="flex flex-wrap mb-3">
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    First Name *
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      onChange={handleChangeInput}
                      className="form-control placeholder-white placeholder-opacity-50 uppercase"
                      name="first_name"
                      value={form.first_name}
                    />
                  </div>
                </div>

                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Last Name *
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      onChange={handleChangeInput}
                      className="form-control placeholder-white placeholder-opacity-50 uppercase"
                      name="last_name"
                      value={form.last_name}
                    />
                  </div>
                </div>

                <div className="w-full px-1 mb-3">
                  <label className="text-xs text-white mb-1 block">
                    Recover By *
                  </label>
                  <div className="form-group-wrapper">
                    <select
                      required
                      onChange={handleChangeInput}
                      className="form-control placeholder-white placeholder-opacity-50"
                      name="recovery_type"
                      placeholder=""
                      value={form.recovery_type}
                    >
                      <option value="">- Select -</option>
                      <option value="email">E-mail Address</option>
                      <option value="mobile">Mobile Number</option>
                    </select>
                  </div>
                </div>

                {form.recovery_type === 'email' && (
                  <div className="w-full px-1 mb-1">
                    <label className="text-xs text-white mb-1 block">
                      Enter your NBI Online Email Address *
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        autoComplete="off"
                        required
                        onChange={handleChangeInput}
                        className="form-control placeholder-white placeholder-opacity-50"
                        name="email"
                        value={form.email}
                      />
                    </div>
                  </div>
                )}

                {form.recovery_type === 'mobile' && (
                  <div className="w-full px-1 pb-3">
                    <label className="text-xs text-white mb-1 block">
                      Enter your NBI Online Mobile Number *
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        autoComplete="off"
                        required
                        onChange={handleChangeInput}
                        className="form-control placeholder-white placeholder-opacity-50"
                        name="mobile"
                        placeholder=""
                        value={form.mobile}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-right mt-3 p-4 border-t border-yellow-800">
        <button
          className="bg-gray-800 hover:bg-gray-800 text-yellow-600 font-bold py-3 px-5 focus:outline-none focus:shadow-outline rounded mr-2"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          className="bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {isLoading ? 'Loading...' : 'Recover'}
        </button>
      </div>
    </form>
  );
}

let formData = {
  clearance_id: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  birth_date: '',
  mothers_maiden_name: '',
  mothers_place_of_birth: '',
  mobile: '',
};

function QuickRenewal() {
  const isLoading = useApiLoading(c.VERIFY, 'post');

  const [form, setForm] = React.useState(formData);

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    actions.verify(form);
  };

  const showRecoverNbiId = (e) => {
    e.preventDefault();
    showModal({
      title: 'Recover NBI ID',
      content: (onClose) => <Content onClose={onClose} />,
    });
  };

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-2xl">ONLINE RENEWAL</div>
              <div className="text-xs text-white mb-1 block">
                Please fill in all information below
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <hr className="border-gray-700 mb-3 mx-1" />
          <form id="card-form" onSubmit={handleSubmit}>
            <div className="w-full">
              <div className="flex flex-wrap mb-3">
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 flex justify-between">
                    Old NBI ID *{' '}
                    <a
                      href="/"
                      className="text-right text-yellow-500 uppercase"
                      onClick={showRecoverNbiId}
                    >
                      Recover NBI ID
                    </a>
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      onChange={handleChangeInput}
                      className="form-control placeholder-white placeholder-opacity-50"
                      name="clearance_id"
                      placeholder="Ex. A123BCDE45 - FG67891"
                      value={form.clearance_id}
                    />
                  </div>
                </div>

                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Mobile Number (For sms notification) *
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      onChange={handleChangeInput}
                      className="form-control placeholder-white placeholder-opacity-50"
                      name="mobile"
                      placeholder="Ex. 09601234567"
                      value={form.mobile}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    First Name *
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      className="form-control placeholder-white placeholder-opacity-50 uppercase"
                      name="first_name"
                      placeholder="Ex: Juan"
                      value={form.first_name}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Middle Name
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      className="form-control placeholder-white placeholder-opacity-50 uppercase"
                      name="middle_name"
                      placeholder="Ex: Pablo"
                      value={form.middle_name}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Last Name *
                  </label>
                  <div className="form-group-wrapper">
                    <input
                      autoComplete="off"
                      required
                      className="form-control placeholder-white placeholder-opacity-50 uppercase"
                      name="last_name"
                      placeholder="Ex: Dela Cruz"
                      value={form.last_name}
                      onChange={handleChangeInput}
                    />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Birthdate * (MM/DD/YYYY)
                  </label>
                  <div className="form-group-wrapper">
                    <InputMask
                      mask="99/99/9999"
                      maskChar={null}
                      autoComplete="off"
                      required
                      className="form-control placeholder-white placeholder-opacity-50"
                      name="birth_date"
                      placeholder="MM/DD/YYYY"
                      onChange={handleChangeInput}
                      value={form.birth_date}
                    />
                  </div>
                </div>
              </div>
              <div className="text-right mb-3 ml-auto mx-1">
                {/* <button
                  className="bg-gray-900 hover:bg-gray-800 text-yellow-600 font-bold py-3 px-5 focus:outline-none focus:shadow-outline rounded mr-2"
                  type="button"
                >
                  Close
                </button> */}
                <button
                  disabled={isLoading}
                  className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  <span className="mr-3">
                    {isLoading ? 'Loading...' : 'Verify'}
                  </span>
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={4}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

QuickRenewal.propTypes = {};

export default QuickRenewal;
