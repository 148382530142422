import React, { useState } from 'react';
import history from 'setup/history';
import { Redirect } from 'react-router-dom';
import * as c from '../constants';
import { useApiGet, useApiLoading } from 'react-reqq';
import _ from 'lodash';
import * as actions from '../actions';
import deliveryBanner from 'assets/img/delivery-banner.png';
import checkO from 'assets/img/circle-check-o.svg';
import check from 'assets/img/circle-check.svg';
import { showModal } from 'modules/common/components/Modal';

function Content({ onClose, form }) {
  const isLoading = useApiLoading(c.GET_REFNO, 'post');

  const handleGetRefno = (onClose) => (e) => {
    e.preventDefault();
    actions.confirmPayment(form);
    onClose();
  };

  return (
    <form onSubmit={handleGetRefno(onClose)}>
      <div className="p-4 text-white">
        <div
          className="relative overflow-auto"
          style={{ paddingBottom: '56.7%' }}
        >
          <div
            className="absolute top-0 h-full w-full text-justify px-2 text-gray-400"
            style={{ fontSize: '12px' }}
          >
            <br />
            <p>
              After choosing <b>"Delivery"</b> option, you will be redirected to
              the <b>Delivery Service</b> page owned by a third-party service
              provider.
            </p>
            <br />
            <p>
              By availing of the <b>Delivery Service</b> owned by a third-party
              service provider, you:{' '}
            </p>
            <br />
            <p>
              (1) authorize the NBI to release your NBI Clearance to third-party
              couriers, in order to carry out necessary <b>delivery service</b>;
            </p>

            <p>
              (2) relieve the NBI of any accountability under the Data Privacy
              Act of 2012 (RA No. 1173); and
            </p>

            <p>
              (3) acknowledge that the NBI shall no longer be responsible for
              your customer experience.
            </p>
          </div>
        </div>
      </div>
      <div className="text-right mt-3 p-4 border-t border-yellow-800">
        <button
          className="bg-gray-800 hover:bg-gray-800 text-yellow-600 font-bold py-3 px-5 focus:outline-none focus:shadow-outline rounded mr-2"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          className="bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {isLoading ? 'Loading...' : 'I Agree'}
        </button>
      </div>
    </form>
  );
}

const CLAIM_OPTIONS = [
  {
    code: 'deliver',
    label: 'For Door to door Delivery',
    description:
      'Ang iyong NBI clearance ay ipapadala sa iyong address matapos mong bayaran ang mga fees',
  },
  {
    code: 'pickup',
    label: 'For Pick-up',
    description: (
      <span>
        Ang iyong NBI Clearance ay maaring ma pick-up <b>lamang</b> sa{' '}
        <b>NBI Clearance Center U.N. Ave. Manila</b>
      </span>
    ),
  },
];

function SelectDeliveryMethod() {
  const isLoading = useApiLoading(c.DELIVERY, 'post');
  const defaultData = useApiGet(c.VERIFY, {});
  const [form, setForm] = React.useState({
    deliveryType: 'deliver',
  });

  const [showOptions, setShowOptions] = useState(false);

  const merged = React.useMemo(() => ({ ...form, defaultData }), [
    form,
    defaultData,
  ]);

  if (_.isEmpty(defaultData)) {
    return <Redirect to="/" />;
  }

  const handlePickup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/payment-option');
  };

  // const handleDelivery = (deliveryType) => (e) => {
  //   setForm({ deliveryType });
  // };

  const showDisclaimer = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showModal({
      title: 'DISCLAIMER NOTICE',
      content: (onClose) => <Content onClose={onClose} form={merged} />,
    });
  };

  if (showOptions) {
    return (
      <div className="container mx-auto max-w-700 text-white">
        <div className="delete-this-div">
          <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
            <div className="px-2 pt-3 pb-0 w-full">
              <div className="text-center">
                <div className="font-bold text-2xl">Mode of Delivery</div>
                <div className="text-xs text-white mb-1 block">
                  How would you like your clearance to be delivered?
                </div>
              </div>
            </div>
          </div>
          <div className="relative px-5 py-3">
            <hr className="border-gray-700 mb-8 mx-1" />
            <div className="mx-auto max-w-xl mb-8 space-y-6">
              {React.Children.toArray(
                CLAIM_OPTIONS.map((item) => {
                  const isSelected = form.deliveryType === item.code;
                  return (
                    <a
                      className={`flex border border-gray-700 rounded-lg p-6 ${
                        isSelected
                          ? 'bg-yellow-600 bg-opacity-25 border-yellow-600'
                          : ''
                      }`}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        setForm({ deliveryType: item.code });
                      }}
                    >
                      <div className="flex-shrink-0 pr-6 flex items-center">
                        {isSelected ? (
                          <img src={check} alt="Check" />
                        ) : (
                          <img src={checkO} alt="Check O" />
                        )}
                      </div>
                      <div className="flex-1 min-w-0">
                        <div className="text-2xl font-bold mb-1">
                          {item.label}
                        </div>
                        <div className="text-xl">{item.description}</div>
                      </div>
                    </a>
                  );
                })
              )}
              <div className="mb-8">
                <button
                  onClick={(e) => {
                    if (form.deliveryType === 'deliver') showDisclaimer(e);
                    if (form.deliveryType === 'pickup') handlePickup(e);
                  }}
                  type="button"
                  className={`w-full uppercase ${'bg-yellow-600 text-gray-900 border-yellow-600'} border-yellow-600 font-bold rounded focus:outline-none focus:shadow-outline border-solid border-2 block py-3`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'PAY'}
                </button>
              </div>
            </div>
            <hr className="border-gray-700 mb-8 mx-1" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-2xl">Mode of Delivery</div>
              <div className="text-xs text-white mb-1 block">
                How would you like your clearance to be delivered?
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <hr className="border-gray-700 mb-8 mx-1" />
          <div className="mx-auto max-w-xl mb-8 space-y-6">
            <img src={deliveryBanner} alt="Delivery Banner" />
            <div className="text-white space-y-4">
              <div className="text-2xl font-bold">
                NBI Clearance Door-to-door Delivery
              </div>
              <div className="text-xl">
                Magbayad para sa door-to-door delivery ng iyong NBI clearance sa
                iyong address.
              </div>
            </div>
            <button
              onClick={showDisclaimer}
              type="button"
              className={`w-full uppercase ${
                form.deliveryType === 'deliver'
                  ? 'bg-yellow-600 text-gray-900 border-yellow-600'
                  : 'bg-gray-900 text-yellow-600'
              } border-yellow-600 font-bold rounded focus:outline-none focus:shadow-outline border-solid border-2 block py-3`}
              disabled={isLoading}
            >
              {isLoading ? 'Loading...' : 'PAY'}
            </button>
          </div>
          <hr className="border-gray-700 mb-3 mx-1" />
          <div className="text-center text-xl">
            <button
              className="text-yellow-600 font-semibold underline"
              type="button"
              onClick={() => setShowOptions(true)}
            >
              Iba pang paraan para makuha ang iyong NBI Clearance
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="container mx-auto max-w-700 text-white">
  //     <div className="delete-this-div">
  //       <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
  //         <div className="px-2 pt-3 pb-0 w-full">
  //           <div className="text-center">
  //             <div className="font-bold text-2xl">Delivery Method</div>
  //             <div className="text-xs text-white mb-1 block">
  //               How would you like your clearance to be delivered?
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="relative px-5 py-3">
  //         <hr className="border-gray-700 mb-3 mx-1" />
  //         <div className="w-full">
  //           <div className="border border-yellow-600 p-5 mb-5 rounded mx-1">
  //             <div className="grid gap-2 grid-cols-2">
  //               <button
  //                 onClick={handleDelivery('pickup')}
  //                 type="button"
  //                 className={`uppercase ${
  //                   form.deliveryType === 'pickup'
  //                     ? 'bg-yellow-600 text-gray-900 border-yellow-600'
  //                     : 'bg-gray-900 text-yellow-600'
  //                   // } hover:bg-yellow-900 hover:bg-opacity-50 text-gray-900 font-bold rounded focus:outline-none focus:shadow-outline border-solid border-yellow-600 hover:border-yellow-400 border-2 block py-3`}
  //                 } border-yellow-600 font-bold rounded focus:outline-none focus:shadow-outline border-solid border-2 block py-3`}
  //               >
  //                 Pick-Up
  //               </button>
  //               <button
  //                 onClick={handleDelivery('deliver')}
  //                 type="button"
  //                 className={`uppercase ${
  //                   form.deliveryType === 'deliver'
  //                     ? 'bg-yellow-600 text-gray-900 border-yellow-600'
  //                     : 'bg-gray-900 text-yellow-600'
  //                 } border-yellow-600 font-bold rounded focus:outline-none focus:shadow-outline border-solid border-2 block py-3`}
  //               >
  //                 Door-to-Door Delivery
  //               </button>
  //             </div>
  //             {form.deliveryType === 'pickup' ? (
  //               <div className="box-arrow box-arrow-right relative p-4 mt-5 text-gray-300 rounded">
  //                 <h1 className="text-lg font-bold mb-1">
  //                   For Pick-Up Instruction
  //                 </h1>
  //                 <p className="text-sm">
  //                   Please click the download button for the detailed
  //                   instruction.
  //                 </p>
  //                 <a
  //                   target="_blank"
  //                   rel="noopener noreferrer"
  //                   href={process.env.REACT_APP_PICKUP_INSTRUCTION}
  //                   className="bg-gray-600 bg-opacity-25 hover:bg-opacity-75 hover:bg-yellow-800 text-yellow-500 px-3 py-2 rounded mt-2 text-xs inline-block"
  //                 >
  //                   Download Pick-Up Instruction
  //                 </a>
  //                 <div className="text-right border-t-2 border-gray-700 mt-3 pt-3">
  //                   <button
  //                     onClick={handlePickup}
  //                     className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
  //                     type="button"
  //                   >
  //                     <span className="mr-3">
  //                       {isLoading ? 'Loading...' : 'Continue to payment'}
  //                     </span>
  //                     <svg
  //                       className="h-4 w-4"
  //                       xmlns="http://www.w3.org/2000/svg"
  //                       fill="none"
  //                       viewBox="0 0 24 24"
  //                       stroke="currentColor"
  //                     >
  //                       <path
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         strokeWidth={4}
  //                         d="M9 5l7 7-7 7"
  //                       />
  //                     </svg>
  //                   </button>
  //                 </div>
  //               </div>
  //             ) : (
  //               ''
  //             )}

  //             {form.deliveryType === 'deliver' ? (
  //               <div className="box-arrow box-arrow-right relative p-4 mt-5 text-gray-300 rounded">
  //                 <h1 className="text-lg font-bold mb-1">
  //                   For Door-to-Door Delivery Instruction
  //                 </h1>
  //                 <p className="text-sm">
  //                   For door-to-door delivery please click proceed...
  //                 </p>
  //                 <div className="text-right border-t-2 border-gray-700 mt-3 pt-3">
  //                   <button
  //                     onClick={showDisclaimer}
  //                     className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
  //                     type="button"
  //                   >
  //                     <span className="mr-3">
  //                       {isLoading ? 'Loading...' : 'Proceed'}
  //                     </span>
  //                     <svg
  //                       className="h-4 w-4"
  //                       xmlns="http://www.w3.org/2000/svg"
  //                       fill="none"
  //                       viewBox="0 0 24 24"
  //                       stroke="currentColor"
  //                     >
  //                       <path
  //                         strokeLinecap="round"
  //                         strokeLinejoin="round"
  //                         strokeWidth={4}
  //                         d="M9 5l7 7-7 7"
  //                       />
  //                     </svg>
  //                   </button>
  //                 </div>
  //               </div>
  //             ) : (
  //               ''
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

SelectDeliveryMethod.propTypes = {};

export default SelectDeliveryMethod;
