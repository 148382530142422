import React from 'react';
import { useApiLoading, useApiGet } from 'react-reqq';
import { Redirect } from 'react-router-dom';
import * as actions from '../actions';
import * as c from '../constants';
import _ from 'lodash';
import history from 'setup/history';

let formData = {
  clearance_id: '',
  mothers_maiden_name: '',
  mothers_place_of_birth: '',
  mobile: '',
  email: '',
  verification_type: '',
};

function SecurityValidation() {
  const isLoading = useApiLoading(c.VERIFY, 'post');

  const [form, setForm] = React.useState({
    ...formData,
    ...useApiGet(c.VERIFY, {}),
  });

  const handleChangeInput = ({ target }) => {
    const { name, value } = target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    actions.validateSecurityOption(form);
  };

  const verificationOption = (e) => {
    const { value } = e.target;
    setForm({
      ...form,
      verification_type: value,
    });
  };

  if (_.isEmpty(form.clearance_id)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-2xl">ONLINE RENEWAL</div>
              <div className="text-xs text-white mb-1 block">
                Please choose verification options.
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <hr className="border-gray-700 mb-3 mx-1" />
          <form id="card-form" onSubmit={handleSubmit}>
            <div className="w-full">
              <div className="flex flex-wrap mb-3">
                <div className="w-full px-1 pb-3">
                  <label className="text-xs text-white mb-1 block">
                    Verification Options
                  </label>
                  <div className="form-group-wrapper">
                    <select
                      required
                      className="form-control placeholder-white placeholder-opacity-50"
                      name="verification_type"
                      value={form.verification_type}
                      onChange={verificationOption}
                    >
                      <option value="">- Select -</option>
                      <option value="MOTHER_INFO">Mother's Information</option>
                      <option value="MOBILE_NUMBER">
                        Mobile No. (Used during registration)
                      </option>
                      <option value="EMAIL_ADDRESS">
                        Email Address (Used during registration)
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              {form.verification_type === 'MOTHER_INFO' ? (
                <div className="flex flex-wrap mb-3">
                  <div className="w-full sm:w-1/2 px-1 pb-3">
                    <label className="text-xs text-white mb-1 block">
                      Mother's Full Name *
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        required
                        className="form-control placeholder-white placeholder-opacity-50 uppercase"
                        name="mothers_maiden_name"
                        placeholder="Ex: Stephen Dizon Barreto"
                        onChange={handleChangeInput}
                        value={form.mothers_maiden_name}
                      />
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 px-1 pb-3">
                    <label className="text-xs text-white mb-1 block">
                      Mother's Birth Place *
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        required
                        className="form-control placeholder-white placeholder-opacity-50 uppercase"
                        name="mothers_place_of_birth"
                        placeholder="Ex: Manila"
                        onChange={handleChangeInput}
                        value={form.mothers_place_of_birth}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {form.verification_type === 'MOBILE_NUMBER' ? (
                <div className="flex flex-wrap mb-3">
                  <div className="w-full px-1 pb-3">
                    <label className="text-xs text-white mb-1 block">
                      Mobile No. (Used during registration)
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        required
                        className="form-control placeholder-white placeholder-opacity-50"
                        name="mobile"
                        onChange={handleChangeInput}
                        value={form.mobile}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {form.verification_type === 'EMAIL_ADDRESS' ? (
                <div className="flex flex-wrap mb-3">
                  <div className="w-full px-1 pb-3">
                    <label className="text-xs text-white mb-1 block">
                      Email Address (Used during registration)
                    </label>
                    <div className="form-group-wrapper">
                      <input
                        required
                        className="form-control placeholder-white placeholder-opacity-50"
                        name="email"
                        onChange={handleChangeInput}
                        value={form.email}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="text-right mb-3 ml-auto mx-1">
                <button
                  className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline mr-3"
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    if (confirm('Are you sure you want to cancel?'))
                      history.push('/');
                  }}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading}
                  className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  <span className="mr-3">
                    {isLoading ? 'Loading...' : 'Verify'}
                  </span>
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={4}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

SecurityValidation.propTypes = {};

export default SecurityValidation;
