import React from 'react';
// import PropTypes from 'prop-types';
import not_found from 'assets/img/page-not-found.png';

function Page404() {
  return (
    <div className="flex">
      <div className="text-center mx-auto mt-10">
        <div className="text-gray-700 text-3xl sm:text-5xl font-light">
          This page isn&apos;t available
        </div>
        <div className="text-gray-700 mb-8 px-10">
          We&apos;re sorry but it looks like that page doesn&apos;t exist.
        </div>
        <div className="mx-auto max-w-700 w-full min-h-200 mb-5">
          <img src={not_found} alt="not found" />
        </div>
      </div>
    </div>
  );
}

Page404.propTypes = {};

export default Page404;
