import React from 'react';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

const closeToast = () => {
  const root = document.getElementById('toast-marker');
  if (root) render(<div id="toast-marker" />, root);
};

// const TIMEOUT = 10000;

const ToastSuccess = ({ message }) => {
  const handleClose = () => {
    closeToast();
  };
  return (
    <div className="h-screen w-screen fixed bg-black z-50 bg-opacity-50 flex fade-in">
      <div className="m-auto relative min-w-250 max-w-320">
        <div className="rounded-lg border border-green-400 bg-green-100 shadow flex items-center relative overflow-hidden z-10">
          <div className="relative z-10">
            <span className="font-bold text-green-700 text-4xl w-20 text-center inline-block">
              &#x2713;
            </span>
          </div>
          <div className="absolute h-0 w-0">
            <div className="h-40 w-40 bg-green-200 rounded-full transform-center" />
          </div>
          <div className="font-semibold text-green-700 py-6 pl-6 pr-3 relative z-10 tracking-tighter leading-tight">
            {message}
          </div>
        </div>
        <div className="absolute top-0 right-0 h-0 w-0 z-20">
          <button
            className="h-8 w-8 border bg-white text-gray-600 shadow-sm flex rounded-full transform-center hover:bg-gray-200 hover:text-gray-900 focus:outline-none"
            onClick={handleClose}
            type="button"
          >
            <span className="m-auto text-lg font-bold leading-none">
              &times;
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const ToastError = ({ message }) => {
  const handleClose = () => {
    closeToast();
  };
  return (
    <div className="h-screen w-screen fixed bg-black z-50 bg-opacity-50 flex fade-in">
      <div className="m-auto relative min-w-250 max-w-320">
        <div className="rounded-lg border border-red-400 bg-red-100 shadow flex items-center relative overflow-hidden z-10">
          <div className="relative z-10">
            <span className="font-bold text-red-700 text-4xl w-20 text-center inline-block">
              &#33;
            </span>
          </div>
          <div className="absolute h-0 w-0">
            <div className="h-40 w-40 bg-red-200 rounded-full transform-center" />
          </div>
          <div className="font-semibold text-red-700 py-6 pl-6 pr-3 relative z-10 tracking-tighter leading-tight">
            {message}
          </div>
        </div>
        <div className="absolute top-0 right-0 h-0 w-0 z-20">
          <button
            className="h-8 w-8 border bg-white text-gray-600 shadow-sm flex rounded-full transform-center hover:bg-gray-200 hover:text-gray-900 focus:outline-none"
            onClick={handleClose}
            type="button"
          >
            <span className="m-auto text-lg font-bold leading-none">
              &times;
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

ToastError.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

export const toastSuccess = (message) => {
  const root = document.getElementById('toast-marker');
  // eslint-disable-next-line no-alert
  if (!root) return alert(message);
  render(<ToastSuccess message={message} />, root);
};

export const toastError = (message) => {
  const root = document.getElementById('toast-marker');
  // eslint-disable-next-line no-alert
  if (!root) return alert(message);
  render(<ToastError message={message} />, root);
};

export default () => <div id="toast-marker" />;
