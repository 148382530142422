import React from 'react';
import ErrorBoundary from 'modules/partial/containers/ErrorBoundary';
import { ModalMarker } from 'modules/common/components/Modal';
import ToastMarker from './toast/index';
import Public from './Public';

function App() {
  return (
    <ErrorBoundary>
      <ToastMarker />
      <ModalMarker />
      <Public />
    </ErrorBoundary>
  );
}

export default App;
