import React from 'react';
import { showModal } from 'modules/common/components/Modal';
import { useApiGet, useApiLoading } from 'react-reqq';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import * as c from '../constants';
import * as actions from '../actions';
import * as extras from '../extras';

function Content({ onClose, instructions }) {
  const form = useApiGet(c.VERIFY, {});
  const isLoading = useApiLoading(c.GET_REFNO, 'post');

  const handleGetRefno = (onClose) => (e) => {
    e.preventDefault();
    actions.getRefno(form, onClose);
  };

  const createMarkUp = (html) => {
    return { __html: html };
  };

  return (
    <form onSubmit={handleGetRefno(onClose)}>
      <div className="p-4 text-white">
        <div
          className="relative overflow-auto"
          style={{ paddingBottom: '56.7%' }}
        >
          <div className="absolute top-0 h-full w-full text-justify px-2 text-gray-400">
            <h4 className="text-lg mb-3">
              Once you click{' '}
              <span className="text-white">Proceed to Payment</span> here are
              the next steps:
            </h4>
            <ol className="list-decimal">
              {instructions.instructions.map((item, idx) => {
                return (
                  <li
                    key={idx}
                    className="border-t-2 border-gray-800 py-2 block inline-flex items-center w-full border-dotted"
                  >
                    <span className="mr-3 font-bold italic bg-gray-700 px-2 py-1 rounded text-center">
                      {idx + 1}
                    </span>
                    <div dangerouslySetInnerHTML={createMarkUp(item)} />
                  </li>
                );
              })}
            </ol>
          </div>
        </div>
      </div>
      <div className="text-right mt-3 p-4 border-t border-yellow-800">
        <button
          className="bg-gray-800 hover:bg-gray-800 text-yellow-600 font-bold py-3 px-5 focus:outline-none focus:shadow-outline rounded mr-2"
          type="button"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          disabled={isLoading}
          className="bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          {isLoading ? 'Loading...' : 'Proceed to Payment'}
        </button>
      </div>
    </form>
  );
}

function PaymentOption() {
  const form = useApiGet(c.VERIFY, {});

  if (_.isEmpty(form)) {
    return <Redirect to="/" />;
  }

  const handleSelectPayment = (data) => (e) => {
    e.preventDefault();
    showModal({
      title: 'Payment Instruction',
      content: (onClose) => <Content onClose={onClose} instructions={data} />,
    });
  };

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-2xl">Payment Options</div>
              <div className="text-xs text-white mb-2 block">
                Please select mode of payment.
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <hr className="border-gray-700 mb-3 mx-1" />
          <form
            id="card-form"
            className="grid gap-2 sm:gap-3 grid-cols-1 sm:grid-cols-2"
          >
            <div className="border rounded p-2 border-gray-700">
              <div className="flex flex-wrap mb-3 mt-1">
                <div className="w-full px-1">
                  <label className="text-xs text-white mb-1 block">
                    Select NBI Branch
                  </label>
                  <div className="inline-block relative w-full">
                    <div className="form-group-wrapper">
                      <select
                        className="form-control block appearance-none w-full"
                        id="grid-state"
                      >
                        <option>NBI Clearance Center U.N. Ave. Manila</option>
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mb-3">
                <div className="w-full px-1">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className="bg-gray-800">
                        <th className="border px-4 py-2 text-left">
                          Particular
                        </th>
                        <th className="border px-4 py-2 text-right">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-4 py-2">
                          Multi-Purpose Clearance
                        </td>
                        <td className="border px-4 py-2 text-right text-2xl">
                          &#8369;{c.CLEARANCE_FEE}
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2 text-2xl">
                          <b>Total</b>
                        </td>
                        <td className="border px-4 py-2 text-right text-yellow-500 text-2xl">
                          <b>&#8369;{c.CLEARANCE_FEE}</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="w-full px-1 mb-2">
                <div className="text-center">
                  <div
                    className="py-5 px-2 bg-red-900 bg-opacity-50 items-center border border-red-800 mb-3 rounded text-center text-2xl"
                    role="alert"
                  >
                    Additional fee of &#8369;{c.SERVICE_FEE} to use this
                    e-payment service.
                  </div>
                </div>
                <div className="text-center text-sm">
                  <span className="divider line one-line">
                    To complete transaction please choose payment option.
                  </span>
                </div>
              </div>
              <div className="grid gap-3 grid-cols-2">
                {extras.paymentOptions.map((item, idx) => {
                  return (
                    <div className="w-full" key={`${idx}-payment-options`}>
                      <a
                        href="/"
                        onClick={handleSelectPayment(item)}
                        className="text-center bg-dark-900 bg-opacity-25 hover:bg-yellow-900 hover:bg-opacity-50 border border-yellow-700 p-1 rounded-md block"
                      >
                        <img
                          src={item.logo}
                          alt="Bank Over The Counter"
                          className="mx-auto w-12 my-2"
                        />
                        <p className="text-yellow-600 text-xs font-bold mb-2">
                          {item.name}
                        </p>
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

PaymentOption.propTypes = {};

export default PaymentOption;
