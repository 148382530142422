import React from 'react';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import * as c from '../constants';
import { useApiGet, useApiLoading } from 'react-reqq';

function PaymentInstruction() {
  const form = useApiGet(c.VERIFY, {});
  const isLoading = useApiLoading(c.GET_REFNO, 'post');

  const handleGetRefno = (e) => {
    e.preventDefault();
    actions.getRefno(form);
  };

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-2xl">PAYMENT INSTRUCTION</div>
              <div className="text-xs text-white mb-1 block">
                Please read the instruction carefully before you proceed
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <form id="card-form" onSubmit={handleGetRefno}>
            <div className="relative px-5 py-3">
              <hr className="border-gray-700 mb-3 mx-1" />
              <div className="mb-5">
                <div className="flex items-center rounded-t bg-red-800 uppercase px-2 py-1 text-md font-bold">
                  <svg
                    className="fill-current h-4 w-4 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                  </svg>
                  <p>A Reminder</p>
                </div>
                <div className="border border-red-800 p-5">
                  <p className="mb-5 font-bold">
                    Once you click PROCEED TO PAYMENT, here are the next steps:
                  </p>
                  <ol className="list-decimal ml-10">
                    <li className="mb-3">
                      A Payment Page will open with your Reference Number.
                    </li>
                    <li className="mb-3">
                      Go to the nearest Bayad Center or LBC and pay using your
                      Reference Number.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="text-right mb-3 ml-auto mx-1">
                <Link
                  to="/payment-option"
                  className="bg-gray-900 hover:bg-gray-800 text-yellow-600 font-bold py-3 px-5 focus:outline-none focus:shadow-outline rounded mr-2"
                  type="button"
                >
                  Back
                </Link>
                <button
                  disabled={isLoading}
                  className="inline-flex items-center bg-yellow-600 text-gray-900 hover:bg-yellow-700 font-bold py-3 px-5 rounded focus:outline-none focus:shadow-outline"
                  type="submit"
                >
                  <span className="mr-3">
                    {isLoading ? 'Loading...' : 'Proceed'}
                  </span>
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={4}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

PaymentInstruction.propTypes = {};

export default PaymentInstruction;
