import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Page404 from 'modules/partial/containers/Page404';
import QuickRenewal from 'modules/delivery/containers/QuickRenewal';
// import Notice from 'modules/delivery/containers/NoticeContainer';
import SecurityValidation from 'modules/delivery/containers/SecurityValidation';
import PaymentOption from 'modules/delivery/containers/PaymentOption';
import PaymentConfirmation from 'modules/delivery/containers/PaymentConfirmation';
import PaymentInstruction from 'modules/delivery/containers/PaymentInstruction';
// import ShippingDetails from 'modules/delivery/containers/ShippingDetails';
import PickupInstruction from 'modules/delivery/containers/PickupInstruction';
import SelectDeliveryMethod from 'modules/delivery/containers/SelectDeliveryMethod';
function Public() {
  return (
    <Switch>
      <Route exact path="/">
        <QuickRenewal />
      </Route>
      <Route path="/security-validation">
        <SecurityValidation />
      </Route>
      <Route path="/payment-option">
        <PaymentOption />
      </Route>
      <Route path="/payment-instruction">
        <PaymentInstruction />
      </Route>
      <Route path="/payment-confirmation">
        <PaymentConfirmation />
      </Route>
      <Route path="/delivey-method">
        <SelectDeliveryMethod />
      </Route>
      {/* <Route path="/shipping-details">
        <ShippingDetails />
      </Route> */}
      <Route path="/pickup-instruction">
        <PickupInstruction />
      </Route>
      <Route component={Page404} />
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
