import React from 'react';
import { Redirect } from 'react-router-dom';
import * as c from '../constants';
import { useApiGet } from 'react-reqq';
import _ from 'lodash';
import QR from 'qrcode.react';

function PaymentConfirmation() {
  const data = useApiGet(c.GET_REFNO, {});
  if (_.isEmpty(data)) {
    return <Redirect to="/" />;
  }

  return (
    <div className="container mx-auto max-w-700 text-white">
      <div className="delete-this-div">
        <div className="bg-dark shadow-lg flex flex-wrap divide-x overflow-hidden relative">
          <div className="px-2 pt-3 pb-0 w-full">
            <div className="text-center">
              <div className="font-bold text-lg">
                This is your Reference Number to pay.
              </div>
              <div className="text-xs text-white mb-1 block">
                Please present the REFERENCE NUMBER and go to any outlets or
                selected partners to pay
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-5 py-3">
          <div className="relative px-5 py-3">
            <hr className="border-gray-700 mb-3 mx-1" />
            <div className="w-full">
              <div className="flex flex-wrap">
                <div className="w-full px-1">
                  <div className="border border-yellow-600 p-5 mb-5 rounded">
                    <div className="flex items-end">
                      <div className="px-3">
                        {data.refno ? (
                          <QR includeMargin value={data.refno} />
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <p>Reference Number</p>
                        <h1 className="text-3xl text-yellow-500 font-mono">
                          {data.refno}
                        </h1>
                        <div
                          className="p-2 bg-red-900 bg-opacity-50 items-center leading-5 flex lg:inline-flex mt-2 rounded"
                          role="alert"
                        >
                          <span className="flex rounded bg-red-800 uppercase px-2 py-1 text-xs font-bold mr-3">
                            Instruction:
                          </span>
                          <span className="font-semibold mr-2 text-left flex-auto text-xs">
                            You can pay this reference number to any Bayad
                            Center Facilities
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="box-arrow relative p-4 mt-5 text-gray-300 rounded">
            <h1 className="text-lg font-bold mb-1">For Pick-Up Instruction</h1>
            <p className="text-sm">
              Please click the download button for the detailed instruction.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={process.env.REACT_APP_PICKUP_INSTRUCTION}
              className="bg-gray-600 bg-opacity-25 hover:bg-opacity-75 hover:bg-yellow-800 text-yellow-500 px-3 py-2 rounded mt-2 text-xs inline-block"
            >
              Download Pick-Up Instruction
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

PaymentConfirmation.propTypes = {};

export default PaymentConfirmation;
