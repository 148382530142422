import history from 'setup/history';
import { req } from 'react-reqq';
import * as c from './constants';
import { toastError, toastSuccess } from 'toast';
import { get, values, mapKeys } from 'lodash';
import { showPreview } from './containers/ProfilePreviewModal';

const toUpperCase = async (payload) => {
  const newPayload = {};
  values(
    mapKeys(payload, (value, key) => {
      newPayload[key] = value.toUpperCase();

      if (key === 'email') {
        newPayload[key] = value.toLowerCase();
      }
    })
  );
  return newPayload;
};

export const verify = async (data) => {
  const payload = await toUpperCase(data);
  req.post({
    key: c.VERIFY,
    url: '/api/v1/pub/renewal/validate',
    payload,
    onSuccess: () => {
      req.set(c.VERIFY, payload);
      // history.push('/payment-option');
      history.push('/security-validation');
    },
  });
};

export const validateSecurityOption = async (data) => {
  const payload = await toUpperCase(data);
  req.post({
    key: c.VERIFY,
    url: '/api/v1/pub/renewal/validate2',
    payload,
    onSuccess: ({ response: res }) => {
      req.set(c.VERIFY, payload);
      const data = {
        photo: res?.data?.photo,
        first_name: res?.data?.fname || '-',
        middle_name: res?.data?.mname || '-',
        last_name: res?.data?.lname || '-',
        husband_surname: res?.data?.husband_surname || 'n/a',
        nickname: res?.data?.alias_name || '-',
        alias: res?.data?.aka_name || '-',
        civil_status: res?.data?.civil_status || '-',
        birth_date: new Date(res?.data?.bday).toLocaleString().split(',')[0],
        birth_place: res?.data?.bplace || '-',
        gender: res?.data?.gender || '-',
        address: res?.data?.address || '-',
        citizenship: res?.data?.citizenship || '-',
        mobile: res?.data?.mobile || '-',
        email: res?.data?.email || '-',

        spouse_name: res?.data?.spouse_name || '-',
        spouse_birth_place: res?.data?.spouse_bplace || '-',
        father_name: res?.data?.father_name || '-',
        father_birth_place: res?.data?.father_bplace || '-',
        mother_name: res?.data?.mother_name || '-',
        mother_birth_place: res?.data?.mother_bplace || '-',
        education_attainment: res?.data?.education || '-',
        occupation: res?.data?.occupation || '-',
        religion: res?.data?.religion || '-',
        height: `${res?.data?.height}cm`,
        weight: `${res?.data?.weight}kg`,
        complexion: res?.data?.complexion || '-',
        identifying_marks: res?.data?.scars_marks || '-',
      };
      showPreview({
        data,
        callback: () => {
          history.push('/delivey-method');
        },
      });
    },
  });
};

export const recoverId = (payload, callback) => {
  req.post({
    key: c.RECOVER,
    url: '/api/v1/pub/renewal/recover_clearance',
    payload,
    onSuccess: (response) => {
      console.log(response);
      toastSuccess('Success');
      callback();
    },
    onError: (err) => {
      const err_message = get(err, 'response.message');
      callback();
      if (err.status === 404) {
        toastError(err_message || 'Bad Request!');
        return;
      }
    },
  });
};

export const selectPayment = (params, onClose) => {
  history.push('/payment-confirmation');
  onClose();
};

/*
const windowOpen = (url) => {
  const title = 'PAYMENT GATEWAY';
  const h = 800;
  const w = 1024;
  // Fixes dual-screen position Most browsers Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;

  window.open(
    url,
    title,
    `scrollbars=yes, width=${w / systemZoom}, height=${
      h / systemZoom
    }, top=${top}, left=${left}`
  );
};
*/
export const confirmPayment = (payload) => {
  if (payload.deliveryType === 'deliver') {
    req.post({
      key: c.DELIVERY,
      url: '/api/v2/pub/renewal/process_delivery',
      payload: {
        refno: payload.refno,
        clearance_id: payload.defaultData.clearance_id,
        mobile: payload.defaultData.mobile,
        email: payload.defaultData.email,
      },
      onSuccess: ({ response }) => {
        const data = response.data;
        window.open(data.url, '_self');
        // windowOpen(data.url);
      },
    });
    return;
  }
  return history.push('/pickup-instruction');
};

export const getRefno = (payload, onClose) => {
  req.post({
    key: c.GET_REFNO,
    url: '/api/v1/pub/renewal/process',
    payload,
    onSuccess: ({ response }) => {
      req.set(c.GET_REFNO, {
        refno: response.data.refno,
      });
      history.push('/payment-confirmation');
      onClose();
    },
  });
};

export const submitShipping = () => {
  req.post({
    key: c.SHIPPING,
    url: '/api/v1/pub/renewal/process_delivery',
    onSuccess: ({ response }) => {
      if (response.data) {
        window.location.href = response.data.url;
      }
    },
  });
};
