import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import App from 'App';
import * as serviceWorker from 'serviceWorker';
import store from 'setup/store';
import history from 'setup/history';
import 'assets/styles/styles.css';

import PdfContainer from 'modules/payment/containers/PdfContainer';

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/pdf/:type">
          <PdfContainer />
        </Route>
        <Route component={App} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
