export const VERIFY = 'DELIVERY/verify';
export const SELECT_PAYMENT = 'DELIVERY/select_payment';
export const SHIPPING = 'DELIVERY/shipping';
export const GET_REFNO = 'DELIVERY/get_refno';
export const DELIVERY = 'DELIVERY/delivery';
export const RECOVER = 'DELIVERY/recover';

export const SERVICE_FEE = '30.00';
export const CLEARANCE_FEE = '130.00';
export const TOTAL_FEE = '160.00';
